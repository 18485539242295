@import "../theme.css";

/* To revisit when doing responsive design editing */
@media only screen and (min-width: 650px) {
  #ContactPage {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
  }

  #inputName {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 20px;
  }

  .inputBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    padding: 2px 10px;
  }

  Form {
    padding: 10px;
  }
}

#ContactPage {
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
}

#inputName {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 20px;
}

.inputBox {
  /* display: flex;
    align-items: center;
    justify-content: center; */
}

h4 {
  padding: 2px 10px;
}

Form {
  padding: 10px;
}
