@import "./theme.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* To revisit when doing responsive design editing */
@media only screen and (min-width: 650px) {
  body {
    width: 100vw;
    min-height: 100vh;
    margin: 0px;
    padding: 0px;
  }
}

@media only screen and (max-width: 649px) {
  body {
    max-width: 100vw;
    min-height: 100vh;
    margin: 0px;
    padding: 0px;
  }
}
