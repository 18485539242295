@import "../theme.css";

.galleryThumbnailComponent {
  margin: 20px;
  width: 300px;
  height: 450px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: end;
}

.galleryThumbnailText {
  background-color: rgba(255, 255, 255, 0.558);
  color: var(--font-lowlight);
  width: 80%;
  text-align: center;
}
