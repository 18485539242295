@import "../theme.css";

/* To revisit when doing responsive design editing */
@media only screen and (min-width: 650px) {
  #frontPageHighlightArea {
    align-items: center;
    position: relative;
  }

  .highlightItem {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15px, max-content));
  }

  .highlightNote {
    background-color: rgb(255, 255, 255, 0.4);
    opacity: 1;
    padding: 15px 20px;
    line-height: 22px;
  }

  .highlightNote.right {
    transform: translateX(3rem);
    text-align: right;
  }
  .highlightNote.left {
    transform: translateX(-3rem);
  }

  .highlightPic {
    min-height: 13rem;
    min-width: 13rem;
    background-repeat: no-repeat;
    background-size: 13rem;
    margin: 10px 25px;
    box-shadow: 0 0 8px 8px white inset;
    border-radius: 12%;
  }

  #highlightLeftPic {
    background-image: url("../pics/highlight-images/highlight-script1.jpeg");
  }

  #highlightRightPic {
    background-image: url("../pics/highlight-images/highlight-script2.jpeg");
  }
}

#frontPageHighlightArea {
  align-items: center;
  position: relative;
}

.highlightItem {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.highlightNote {
  background-color: rgb(255, 255, 255, 0.4);
  opacity: 1;
  padding: 15px 20px;
  line-height: 22px;
  text-align: center;
}

.highlightNote.right {
}
.highlightNote.left {
}

.highlightPic {
  min-height: 13rem;
  min-width: 13rem;
  background-repeat: no-repeat;
  background-size: 13rem;
  margin: 10px 25px;
  box-shadow: 0 0 8px 8px white inset;
  border-radius: 12%;
}

#highlightLeftPic {
  background-image: url("../pics/highlight-images/highlight-script1.jpeg");
}

#highlightRightPic {
  background-image: url("../pics/highlight-images/highlight-script2.jpeg");
}
