@import "../theme.css";

.photo-grid {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
}
