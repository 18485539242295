@import "../theme.css";

/* To revisit when doing responsive design editing */
@media only screen and (min-width: 650px) {
  #about-section {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }

  #about-commentary {
    width: 60%;
  }

  #artist-portrait {
    width: 200px;
    height: 200px;
    background-image: url("../pics/wire_frame_box.png");
    background-size: 200px;
    background-repeat: no-repeat;
  }
}

#about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

#about-commentary {
  padding: 5px;
  margin: 10px;
  text-align: center;
}

#artist-portrait {
  width: 200px;
  height: 200px;
  background-image: url("../pics/wire_frame_box.png");
  background-size: 200px;
  background-repeat: no-repeat;
}
