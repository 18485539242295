:root {
  --font-primary: #a4ae8c;
  --font-highlight: #8a8787;
  --font-lowlight: #4a4949;
  --background-main: #ffffff;
  --theme-primary: #a4ae8c;
  --theme-primary-highlight: #d0d5c2;
  --theme-secondary: #c5b097;
  --theme-highlight: #8a8787;
  --theme-lowlight: #3b3b3b;

  font-family: "Cormorant Garamond", serif;
  color: var(--font-lowlight);
  padding: 0px;
  margin: 0px;
}

.page-header {
  color: var(--font-lowlight);
  text-align: center;
}
.sub-header {
  color: var(--font-primary);
  text-align: center;
}
.text {
  color: var(--font-highlight);
}

p {
  padding: 10px;
  line-height: 22px;
  letter-spacing: 0.7px;
}

h1 {
  text-align: center;
}

h2 {
  color: var(--font-lowlight);
  text-align: center;
  border-bottom: 3px var(--theme-highlight) double;
  padding: 15px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 2px;
}

h3 {
  color: var(--font-primary);
  text-align: center;
  padding: 15px;
  letter-spacing: 1.5px;
}

h4 {
  color: var(--theme-secondary);
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  padding: 15px;
}

a {
  color: var(--font-highlight);
}
a:visited {
  color: var(--font-highlight);
}
a:hover {
  color: var(--font-primary);
}
a:focus {
  color: var(--font-highlight);
}
a:hover,
a:active {
  color: var(--font-primary);
}
