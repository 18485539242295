@import "../theme.css";

#FAQ {
  max-width: 800px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

#FAQ h2 {
  width: 100%;
}

#FAQ h3 {
  font-size: 22px;
  padding: 5px;
  margin: 10px;
  border-bottom: 1px var(--theme-highlight);
  max-width: 480px;
}

/* To revisit when doing responsive design editing */
/* @media only screen and (min-width: 920px) {
}

@media only screen and (max-width: 600px) {
  .Header {
    display: flex;
  }
}
*/
