@import "../theme.css";

/* To revisit when doing responsive design editing */
@media only screen and (min-width: 650px) {
  #Footer {
    font-weight: bold;
    width: 100vw;
    padding: 5px;
    z-index: 4;
    display: inline-grid;
    grid-template-columns: repeat(12, 1fr);
    background-color: var(--background-main);
    border-top: 3px solid var(--theme-highlight);
  }

  #footerNavBarSection {
    grid-column: span 12;
    display: flex;
    justify-content: space-around;
  }

  .footerNavBarItem {
    width: 6rem;
    padding: 15px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    color: var(--font-highlight);
  }
  .footerNavBarItem:visited {
    text-decoration: none;
    color: var(--font-highlight);
  }

  .footerNavBarItem:hover {
    text-decoration: none;
    color: var(--font-lowlight);
  }
  .footerNavBarItem:focus {
    text-decoration: none;
    color: var(--font-lowlight);
  }
  .footerNavBarItem:hover,
  a:active {
    text-decoration: none;
    color: var(--font-lowlight);
  }

  #footerSignature {
    text-align: center;
    grid-column: span 12;
    color: var(--font-primary);
  }

  #footerSignature h4 {
    color: var(--font-primary);
  }
}

#Footer {
  font-weight: bold;
  width: 100%;
  z-index: 4;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-main);
  border-top: 3px solid var(--theme-highlight);
}

#footerNavBox {
  display: flex;
}

.footerNavSegment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

#footerNavBarSection {
  grid-column: span 12;
  display: flex;
  justify-content: space-around;
}

.footerNavBarItem {
  padding: 10px;
  text-align: center;
  text-decoration: none;
  color: var(--font-highlight);
}
.footerNavBarItem:visited {
  text-decoration: none;
  color: var(--font-highlight);
}

.footerNavBarItem:hover {
  text-decoration: none;
  color: var(--font-lowlight);
}
.footerNavBarItem:focus {
  text-decoration: none;
  color: var(--font-lowlight);
}
.footerNavBarItem:hover,
a:active {
  text-decoration: none;
  color: var(--font-lowlight);
}

#footerSignature {
  text-align: center;
  color: var(--font-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

#footerSignature h4 {
  padding: 0px;
  margin: 0px;
  color: var(--font-primary);
}
#footerSignature p {
  padding: 0px;
  margin: 0px;
  color: var(--font-primary);
}
