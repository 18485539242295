@import "../theme.css";

/* To revisit when doing responsive design editing */
.slideshow {
  margin: 15px auto;
  overflow: hidden;
  width: 19rem;

  transition: ease 1000ms;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 3000ms;
}

.slide {
  display: inline-block;
  height: 14rem;
  width: 19rem;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  border-radius: 10%;
  background-color: var(--theme-highlight);
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: var(--theme-primary-highlight);
}

.slideshowDot.active {
  background-color: var(--theme-primary);
}

@media only screen and (min-width: 920px) {
  .slideshow {
    margin: 15px auto;
    overflow: hidden;
    width: 36rem;
    transition: ease 1000ms;
  }

  .slideshowSlider {
    white-space: nowrap;
    transition: ease 3000ms;
  }

  .slide {
    display: inline-block;
    height: 26rem;
    width: 36rem;
    background-position-x: center;
    background-position-y: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: 10%;
    background-color: var(--theme-highlight);
  }

  /* Buttons */

  .slideshowDots {
    text-align: center;
  }

  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: var(--theme-primary-highlight);
  }

  .slideshowDot.active {
    background-color: var(--theme-primary);
  }
}
