@import "../theme.css";

#termsAndConditions {
  width: 60vw;
}

/* To revisit when doing responsive design editing */
/* @media only screen and (min-width: 920px) {
}

@media only screen and (max-width: 600px) {
  .Header {
    display: flex;
  }
}
*/
