.galleryPhoto {
  max-width: 90%;
}

@media only screen and (min-width: 650px) {
  .galleryPhoto {
    max-height: 30vh;
    max-width: 30vw;
  }
}
