section {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.5s ease-out, opacity 1.5s ease-out;
  will-change: opacity, visibility;
  width: 100%;
  display: grid;
  justify-items: center;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
