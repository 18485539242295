@import "../theme.css";

.gallery-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* To revisit when doing responsive design editing */
/* @media only screen and (min-width: 920px) {
}

@media only screen and (max-width: 600px) {
  .Header {
    display: flex;
  }
}
*/
