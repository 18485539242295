@import "../theme.css";

/* To revisit when doing responsive design editing */
@media only screen and (min-width: 650px) {
  #PageBody {
    flex: 1;
    display: flex;
    width: 80%;
    justify-content: center;
    position: relative;
    z-index: 2;
    background-color: var(--background-main);
  }
}

#PageBody {
  flex: 1;
  display: flex;
  max-width: 100vw;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: var(--background-main);
}
